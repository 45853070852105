import React, { useEffect, useRef } from 'react';

import { colors } from '../../styles/variables';

import { SectionAppearanceProvider } from '../../components/sections/SectionContext';

import Seo from '../../components/seo';
import {
    SectionHeroStart,
    SectionOrderedItems,
    SectionIconItems,
    SectionAccordion,
    SectionInlineTiles,
    SectionBigTiles,
} from '../../components/sections';

import {
    START_HERO_CONFIG,
    START_ORDERED_ITEMS_CONFIG,
    START_ICON_ITEMS_CONFIG,
    START_ACCORDION_SECTION,
    START_INLINE_TILES_CONFIG,
    START_BIG_TILES_CONFIG,
} from '../../content_config_start_ua';

const Index = () => {
    const pageBodyRef = useRef(null);

    useEffect(() => {
        const paypoDataPayload = {
            pageBodyHeight: pageBodyRef.current.clientHeight,
        };
    
        window.top.postMessage(JSON.stringify(paypoDataPayload), '*');
    }, []);
    
    return (
        <div className="page-body" ref={ pageBodyRef }>
            <SectionAppearanceProvider>
                <Seo title="Start PayPo.pl" />

                <SectionHeroStart
                    config={ START_HERO_CONFIG }
                />

                <SectionOrderedItems
                    config={ START_ORDERED_ITEMS_CONFIG }
                />

                <SectionIconItems
                    config={ START_ICON_ITEMS_CONFIG }
                    background={ colors.grayCultured }
                />

                <SectionAccordion
                    config={ START_ACCORDION_SECTION }
                    isColorSectionAbove={ true }
                />

                <SectionInlineTiles
                    config={ START_INLINE_TILES_CONFIG }
                />

                <SectionBigTiles
                    config={ START_BIG_TILES_CONFIG }
                />
            </SectionAppearanceProvider>
        </div>
    );
};

export default Index;

import {
    ItemBag,
    ItemBlocks,
    ItemBox,
    ItemBoxes,
    ItemCalendar,
    ItemGift,
    ItemMoney,
    ItemStopwatch,
} from './assets/images';

import { colors } from './styles/variables';

import {
    heroRef,
    howItWorksRef,
    benefitsRef,
    faqRef,
    opinionsRef,
    contactRef,
} from './utils/sectionRefs';

export const START_NAVBAR_CONFIG = {
    navigationLinks: [
        {
            id: 1,
            name: "Jak to działa?",
            relatedSection: howItWorksRef.navigationRef,
            action: () => howItWorksRef.moveToSection(),

        },
        {
            id: 2,
            name: "Korzyści",
            relatedSection: benefitsRef.navigationRef,
            action: () => benefitsRef.moveToSection(),
        },
        {
            id: 3,
            name: "FAQ",
            relatedSection: faqRef.navigationRef,
            action: () => faqRef.moveToSection(),
        },
        {
            id: 4,
            name: "Opinie",
            relatedSection: opinionsRef.navigationRef,
            action: () => opinionsRef.moveToSection(),
        },
        {
            id: 5,
            name: "Kontakt",
            relatedSection: contactRef.navigationRef,
            action: () => contactRef.moveToSection(),
        },
    ],
};

export const START_HERO_CONFIG = {
    title: "Купуйте зараз, сплачуйте через 30 днів",
    id: "section-hero",
    forwardedRef: heroRef.forwardedRef,
    navigationRef: heroRef.navigationRef,
    subtitle: "",
    content: [
        {
            text: "Ви, як завжди, замовляєте товари в магазинах, які підтримують PayPo. Забираєте свої покупки, перевіряєте, чи вони вам підходять, і сплачуєте їх протягом 30 днів після оформлення замовлення. Без жодних коштів.",
        },
    ],
    heroGraphic: "start",
};

export const START_ORDERED_ITEMS_CONFIG = {
    title: "Як це працює?",
    id: "section-how-it-works",
    forwardedRef: howItWorksRef.forwardedRef,
    navigationRef: howItWorksRef.navigationRef,
    itemList: [
        {
            id: 1,
            title: "КРОК 1",
            text: "Роблячи покупки в магазині, ви обираєте PayPo як спосіб сплати.",
        },
        {
            id: 2,
            title: "КРОК 2",
            text: "Якщо у вас ще немає облікового запису в PayPo, ви вводите свої дані. Наступні покупки ви підтверджуєте лише за допомогою SMS-коду або одним кліком у мобільному застосунку.",
        },
        {
            id: 3,
            title: "КРОК 3",
            text: "PayPo сплачує ваші покупки, і ви отримуєте своє замовлення.",
        },
        {
            id: 4,
            title: "КРОК 4",
            text: "Ви перевіряєте покупки вдома, і у вас є до 30 днів, щоб розрахуватися з PayPo. Пам'ятайте, що ви платите лише за ті товари, які залишаєте собі.",
        }
    ],
    squares: [
        {
            top: "11.5rem",
            left: "-17rem",
            size: "20rem",
            bgColor: colors.paypoGreen500,
            rotate: "45deg",
        }
    ],
};

export const START_ICON_ITEMS_CONFIG = {
    title: "Переваги",
    id: "section-benefits",
    forwardedRef: benefitsRef.forwardedRef,
    navigationRef: benefitsRef.navigationRef,
    content: [
        {
            Icon: ItemCalendar,
            description: "У вас є до 30 днів на сплату - <b>без додаткових коштів</b>",
        },
        {
            Icon: ItemBlocks,
            description: "<b>Зручний досвід покупок</b> - ви отримуєте нагадування про терміни сплати на електронну пошту та SMS",
        },
        {
            Icon: ItemBoxes,
            description: "<b>Перевіряйте товари вдома</b> - повертайте невдалі покупки та сплачуйте лише за ті, що залишилися",
        },
        {
            Icon: ItemGift,
            description: "<b>Ви можете дозволити собі більше</b> - відривайтеся на розпродажах або купуйте подарунок собі чи близьким, а сплату покупок розподіляйте в часі",
        },
        {
            Icon: ItemBag,
            description: "<b>Ви робите покупки з упевненістю</b> - спочатку отримуйте та перевіряйте замовлення, а сплачуйте лише тоді, коли впевнені у своїй покупці",
        },
        {
            Icon: ItemBox,
            description: "<b>Ви не втрачаєте унікальні можливості</b> - купуєте тоді, коли це найвигідніше, а сплачуєте зручно пізніше",
        },
        {
            Icon: ItemMoney,
            description: "З часом ви можете робити ще більше -<b>ваш ліміт на наступні покупки може збільшитися до 5 000 злотих</b>",
        },
        {
            Icon: ItemStopwatch,
            description: "<b>Миттєві та безпечні платежі</b> - ви підтверджуєте наступні транзакції лише SMS-кодом або одним кліком у додатку",
        },
    ],
    additionalText: [
        {
            text: 'Послуга доступна для нових клієнтів PayPo. Інформація не є офертою. Більш детальну інформацію можна знайти в Регламенті послуги "Сплатити за 30 днів", доступному на сайті <a href=\'https://www.paypo.pl\'>www.paypo.pl</a>. Після того, як клієнти PayPo повністю сплатять одну транзакцію, вони починають користуватися послугами, які дозволяють сплатити замовлення протягом 30 днів або частинами. <a href=\'https://www.paypo.pl/#section-state-control-buttons\'>Перевірте</a>.',
        },
    ],
    squares: [
        {
            bottom: "-14rem",
            right: "-13rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
    ],
};

export const START_ACCORDION_SECTION = {
    title: "Поширені запитання",
    id: "section-faq",
    forwardedRef: faqRef.forwardedRef,
    navigationRef: faqRef.navigationRef,
    content: [
        {
            id: "1",
            title: "1. Чи потрібно вводити дані моєї кредитної/дебетової картки?",
            text: "Ні, нам не потрібні дані вашої кредитної/дебетової картки для використання платежів PayPo.",
            isExpanded: true,
        },
        {
            id: "2",
            title: "2. Як я можу сплатити замовлення?",
            text: "Ви можете сплатити замовлення банківським переказом, поштовим переказом або швидким онлайн-платежем - як вам зручніше. Номер рахунку для сплати ви отримаєте електронною поштою разом з підтвердженням замовлення. Для вашої зручності ми надаємо Панель клієнта - ви не зобов'язані входити в систему, але можете. Там ви можете стежити за всією важливою інформацією.",
        },
        {
            id: "3",
            title: "3. Чи це безпечно?",
            text: "Так, всі платежі здійснюються через зашифроване з'єднання, і ви не надаєте жодних даних для доступу до свого облікового запису.",
        },
        {
            id: "4",
            title: "4. Це щось коштує?",
            text: "Ні. Коли ви користуєтеся послугою “Сплатити за 30 днів“, ви повертаєте лише вартість вашого замовлення протягом 30 днів - без жодних додаткових коштів.",
        },
        {
            id: "5",
            title: "5. Чи потрібно вводити дані моєї кредитної/дебетової картки?",
            text: "У вас є до 30 днів, щоб сплатити замовлення. Ми нагадаємо вам про наближення терміну сплати за допомогою SMS та електронної пошти",
        },
        {
            id: "6",
            title: "6. Що буде, якщо я поверну замовлення?",
            text: "Ви просто не платите. Ваша транзакція буде скасована в нашій системі.",
        },
    ],
    squares: [
        {
            top: "54rem",
            left: "13rem",
            size: "6rem",
            bgColor: colors.fuchsia500,
            rotate: "78.54deg",
        },
        {
            top: "6rem",
            right: "10rem",
            size: "10rem",
            bgColor: colors.fuchsia500,
            rotate: "20.46deg",
        },
        {
            top: "-6rem",
            right: "-13rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
    ],
};

export const START_INLINE_TILES_CONFIG = {
    title: "Перегляньте понад 27 000 позитивних відгуків",
    id: "section-opinions",
    forwardedRef: opinionsRef.forwardedRef,
    navigationRef: opinionsRef.navigationRef,
    tileList: [
        {
            id: 1,
            score: '5.0',
            name: 'Moніка',
            text: 'Я дуже задоволений своїми платежами через PayPo. Коли мені щось потрібно, я можу зробити покупку швидко та ефективно, а потім здійснити платежі в зручний час.',
        },
        {
            id: 2,
            score: '5.0',
            name: 'Maгдалена',
            text: 'Супер - для мене найшвидший і найзручніший спосіб оплачувати посилки, які я можу спокійно подивитися, протестувати, приміряти або перевірити вдома. Без нервів, без поспіху і без зайвого заморожування готівки. І водночас надійно і безпечно. РЕКОМЕНДУЮ',
        },
        {
            id: 3,
            score: '5.0',
            name: 'Aлла',
            text: 'Я ще жодного разу не була розчарована сервісом PayPo. Зрозумілі та прозорі правила. Хороша система сповіщень. Користуюся і буду користуватися.',
        },
    ],
    squares: [
        {
            bottom: "18rem",
            left: "-8rem",
            size: "10rem",
            bgColor: colors.fuchsia500,
            rotate: "20.46deg",
        },
        {
            bottom: "-4.8rem",
            left: "-12rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
        {
            top: "24.8rem",
            right: "31rem",
            size: "14rem",
            bgColor: colors.paypoGreen500,
            rotate: "69.24deg",
        },
    ],
};

export const START_BIG_TILES_CONFIG = {
    title: "У вас є запитання?",
    id: "section-contact",
    forwardedRef: contactRef.forwardedRef,
    navigationRef: contactRef.navigationRef,
    tileList: [
        {
            id: 1,
            bgColor: colors.mintCream,
            content: {
                title: "bok@paypo.pl",
                textContent: [
                    {
                        id: 1,
                        text: "На більшість запитань ми відповідаємо протягом 48 годин",
                    },
                ],
                cta: {
                    type: "button",
                    action: "email",
                    variant: "primary",
                    text: "Напишіть нам"
                }
            },
        },
        {
            id: 2,
            bgColor: colors.palePurple,
            content: {
                title: "+48 22 333 74 60",
                textContent: [
                    {
                        id: 1,
                        text: "З понеділка по п'ятницю з 8:00 до 18:00.",
                    },
                    {
                        id: 2,
                        text: "Вартість дзвінка згідно з тарифом оператора",
                    },
                ],
                cta: {
                    type: "textButton",
                    action: "phoneCall",
                    variant: "textButton",
                    text: "Зателефонуйте нам",
                },
            },
        },
    ],
    squares: [
        {
            top: "-15.2rem",
            left: "-12rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
    ],
};
